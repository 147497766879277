


















































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_SEARCH } from '../store';
import { ApiResponse } from '@/components/types';
import { AuthorizationMixin } from '@/views/mixin';
import { SearchFile, SearchParams } from '@/modules/fileManagement/types';
import format from '@/filter/dateFormat';
import ConservatorSelect from '@/modules/conservator/components/ConservatorSelect.vue';
import ConservatorshipSelect from '@/modules/conservatorship/components/ConservatorshipSelect.vue';
import { routeToLocation } from '@/router';

const FileManagement = namespace('fileManagement');

const SearchTable = () => import('../components/SearchTable.vue');
const SearchCards = () => import('../components/SearchCards.vue');

@Component({
  components: {
    SearchTable,
    SearchCards,
    ConservatorSelect,
    ConservatorshipSelect
  }
})
export default class Search extends AuthorizationMixin {
  @FileManagement.Action(FETCH_SEARCH) fetch!: (search: SearchParams) => Promise<ApiResponse<SearchFile[]>>;

  loading: boolean = false;
  results: SearchFile[] = [];
  error: any = null;
  searched: boolean = false;

  get items() {
    return this.results.map(file => ({
      ...file,
      createdTrans: format(file.created, 'dd.MM.yyyy HH:mm')
    }));
  }

  get employee(): string {
    return this.$route.query['employee'] as string;
  }

  set employee(employee: string) {
    if (!employee) {
      const { employee, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {
      });
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, employee } })).catch(() => {
    });
  }

  get conservatorship(): string {
    return this.$route.query['conservatorship'] as string;
  }

  set conservatorship(conservatorship: string) {
    if (!conservatorship) {
      const { conservatorship, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {
      });
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, conservatorship } })).catch(() => {
    });
  }

  get search(): string {
    return this.$route.query['search'] as string;
  }

  set search(search: string) {
    if (!search) {
      const { search, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {
      });
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, search } })).catch(() => {
    });
  }

  reset() {
    this.search = '';
    this.employee = '';
    this.conservatorship = '';

    this.loading = false;
    this.results = [];
  }

  async searchFiles() {
    this.error = null;
    this.searched = false;

    this.loading = true;
    const { error, content } = await this.fetch({
      search: this.search,
      employee_id: this.employee,
      conservatorship_id: this.conservatorship
    });
    this.loading = false;

    if (error) {
      this.error = error;

      return;
    }

    this.searched = true;

    if (!content) return;

    this.results = content;
  }

  created() {
    if (!this.search && !this.employee && !this.conservatorship) return;

    return this.searchFiles();
  }
}
